* {
    border: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
body {
    font-family: Lato, Helvetica, Arial, sans-serif;
    background: #ffffff;
  }
  a {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    text-decoration: none;
  }
  #navbar {
    background: rgb(180, 180, 180);
    color: rgb(0, 0, 0);
    position: fixed;
    top: 0;
    height: 60px;
    line-height: 60px;
    width: 100%;
    z-index: 10;
  }
  .nav-wrapper {
    margin: auto;
    width: 70%;
    
  } #logo {
    display: block;
    width: 18%;
    max-width: 160px;
    position: absolute;
    left: 45%;
} @media(max-width: 800px) {

 #logo {
    display: block;
    width: 20%;
    max-width: 160px;
    position: absolute;
    left: 40%;
} 
}@media(max-width: 768px) {
      .nav-wrapper {
        width: 90%;

        
      }
    } @media(max-width: 638px) {
        .nav-wrapper {
          width: 100%;

        }
      
    }
      
      .about {
        float: left;
        text-align: left;
        margin-left: 0%;
        margin-right: auto;
        margin-top: 2%;
      }
      .shop{
        float: right;
        text-align: right;
        margin-left: auto;
        margin-right: 6%;
        margin-top: 2%;
      }
     .shop:hover a{
        color: grey;
        transition: all 0.5s ease;
      }
      .about:hover a{
        color: grey;
        transition: all 0.5s ease;
      }
      #menu {
        font-size: 1.5em;
        color:#000000;
        height: 0px;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding-bottom: 7%;
      } 
      #navbar ul {
        display: inline-block;
        margin-left: auto;
        margin-right: 0;
        
        list-style: none;
        /* margin-right: 14px; */
        margin-top: -2px;
        text-align: right;
        transition: transform 0.5s ease-out;
        -webkit-transition: transform 0.5s ease-out;
      } @media(max-width: 640px) {
          #navbar ul {
            display: none;
          }
        } @media(orientation: landscape) {
            #navbar ul {
              display: inline-block;
            }
          }
          #navbar li {
            display: inline-block;
            color:#000000
          }
          #navbar li a {
            color: rgb(181, 48, 48);
            display: block;
            font-size: 0.7em;
            height: 50px;
            letter-spacing: 1px;
            margin: 0 20px;
            padding: 0 4px;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
            transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
          }